"use strict";

/* eslint-disable func-style */
(function ($) {
  $(document).ready(function () {
    (function () {
      var swiper1 = new Swiper('.testimonials .swiper-container', {
        centeredSlides: true,
        roundLengths: true,
        loop: true,
        spaceBetween: 30,
        slidesPerView: 1,
        scrollbar: {
          el: ".swiper-scrollbar"
        },
        breakpoints: {
          768: {
            slidesPerView: 2
          },
          1100: {
            slidesPerView: 3
          },
          1460: {
            slidesPerView: 4
          }
        }
      });
      var swiper2 = new Swiper('.customers .swiper-container', {
        loop: false,
        spaceBetween: 10,
        slidesPerView: 1,
        scrollbar: {
          el: ".swiper-scrollbar"
        }
      });

      if (window.matchMedia("(max-width: 1209px)").matches) {
        var swiper3 = new Swiper('.thinking .swiper-container', {
          loop: false,
          spaceBetween: 30,
          slidesPerView: 1,
          autoplay: {
            delay: 2000,
            disableOnInteraction: false
          }
        });
      }

      var swiper4 = new Swiper('.clarity .swiper-container', {
        loop: true,
        spaceBetween: 30,
        slidesPerView: 'auto',
        centeredSlides: true,
        breakpoints: {
          1210: {
            spaceBetween: 100
          }
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        }
      });
      var swiper5 = new Swiper('.filter .swiper-container', {
        loop: false,
        spaceBetween: 10,
        slidesPerView: 1,
        scrollbar: {
          el: ".swiper-scrollbar"
        }
      });
      var slideMultiple = document.querySelector('.our-team__desc-slider');
      var swiper6 = new Swiper('.our-team__desc-slider', {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 1,
        allowTouchMove: false
      });
      var swiper7 = new Swiper('.our-team__image-slider', {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 1,
        allowTouchMove: true,
        thumbs: {
          swiper: swiper6
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });

      if (slideMultiple) {
        swiper7.controller.control = swiper6;
      }

      var swiper8 = new Swiper('.products .swiper-container, .people__percent-slider, .agency__percent.mobile', {
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        }
      });
    })();
  });
})(jQuery);