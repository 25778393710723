"use strict";

/* eslint-disable func-style */
var $ = jQuery; // eslint-disable-next-line no-shadow

(function ($) {
  $(document).ready(function () {
    (function () {
      var $grid = $('.filter__grid').isotope({
        itemSelector: '.filter__item',
        layoutMode: 'fitRows'
      });
      $('.filter__nav-link').on('click', function () {
        var filterValue = $(this).attr('data-filter');
        $grid.isotope({
          filter: filterValue
        });
      });
    })();
  });
})(jQuery);