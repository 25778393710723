"use strict";

jQuery(".why__desc-link").click(function () {
  if (jQuery(this).hasClass("clicked")) {
    jQuery(this).text("Mehr erfahren");
    jQuery(this).parent().parent().find(".col__item").show();
    jQuery(this).parent().find(".why__extended").hide();
    jQuery(this).removeClass("clicked");
  } else {
    jQuery(this).text("Weniger erfahren");
    jQuery(this).parent().parent().find(".col__item").hide();
    jQuery(this).parent().show();
    jQuery(this).parent().find(".why__extended").show();
    jQuery(this).addClass("clicked");
  }
}); // setInterval(function() {
//   if (!jQuery(".why__card:last-child").hasClass("swiper-slide-active") && jQuery(".why__desc-link.clicked").length > 0) {
//       jQuery(".why__desc-link.clicked").text("Mehr erfahren");
//       jQuery(".why__desc-link.clicked").parent().parent().find(".col__item").show();
//       jQuery(".why__desc-link.clicked").parent().find(".why__extended").hide();
//       jQuery(".why__desc-link.clicked").removeClass("clicked")
//   }
// }, 30);