"use strict";

/* eslint-disable func-style */
var tab = function tab(nav, content) {
  var tabNav = document.querySelectorAll(nav);
  var tabContent = document.querySelectorAll(content);
  var tabName; // eslint-disable-next-line func-style
  // eslint-disable-next-line no-shadow

  function selectTabContent(tabName) {
    tabContent.forEach(function (item) {
      if (item.classList.contains(tabName)) {
        item.classList.add('is-active'); // eslint-disable-next-line no-param-reassign

        item.parentNode.style.height = "".concat(item.offsetHeight, "px");
      } else {
        item.classList.remove('is-active');
      }
    });
  }

  function selectTabNav() {
    tabNav.forEach(function (item) {
      item.classList.remove('is-active');
    });
    this.classList.add('is-active');
    tabName = this.dataset.tabName;
    selectTabContent(tabName);
  }

  tabNav.forEach(function (item) {
    item.addEventListener('click', selectTabNav);
  }); // Initial Height for active content

  tabContent.forEach(function (item) {
    if (item.classList.contains('is-active')) // eslint-disable-next-line no-param-reassign
      item.parentNode.style.height = "".concat(item.offsetHeight, "px");
  });
};

tab('.trends-tabs__title', '.trends-tabs__content-wrap');
tab('.measure__formula-pointer', '.measure__formula-desc-item'); // Open Tabs

function openTabs(selector) {
  var trigger = document.querySelectorAll(selector);
  var arrow = document.querySelectorAll('.faq__list-arrow-down');
  trigger.forEach(function (el) {
    el.addEventListener('click', function () {
      if (this.parentNode.classList.contains('open')) this.parentNode.classList.remove('open');else this.parentNode.classList.add('open');
    });
  });

  if (arrow) {
    arrow.forEach(function (e) {
      e.addEventListener('click', function () {
        this.parentNode.parentNode.classList.remove('open');
      });
    });
  }
}

if (window.matchMedia("(max-width: 767px)").matches) {
  openTabs('.technologies__item-head');
}

openTabs('.faq__list-wrap'); // Position Section Mobile Accordion

if (window.matchMedia("(max-width: 1209px)").matches) {
  var positionHead = document.querySelectorAll('.position__header');
  var close = document.querySelectorAll('.position__trigger');
  positionHead.forEach(function (el) {
    el.addEventListener('click', function () {
      el.parentNode.classList.add('open');
    });
  });
  close.forEach(function (el) {
    el.addEventListener('click', function () {
      el.parentNode.parentNode.classList.remove('open');
    });
  });
}