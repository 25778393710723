"use strict";

var headerClass = document.querySelector('.header').classList;
var headerHeight = document.querySelector('.header').offsetHeight;
var lastScrollY = window.scrollY;

var checkScrollState = function checkScrollState() {
  if (lastScrollY < window.scrollY && lastScrollY > headerHeight) {
    headerClass.add('collapse');
  } else {
    headerClass.remove('collapse');
  }

  lastScrollY = window.scrollY;
};

window.addEventListener('scroll', function () {
  checkScrollState();
});