"use strict";

// eslint-disable-next-line func-style
function scrollOverflow(selector) {
  var item = document.querySelectorAll(selector);
  window.addEventListener('scroll', function () {
    for (var i = 0; i < item.length; i++) {
      var scrollTop = item[i].getBoundingClientRect().top;
      var elemHeight = item[i].getBoundingClientRect().height;

      if (scrollTop < window.innerHeight / 2 && +scrollTop + elemHeight > window.innerHeight / 2) {
        item[i].classList.add('active');
      } else {
        item[i].classList.remove('active');
      }
    }
  });
}

scrollOverflow('.overflow__item');

if (window.matchMedia("(min-width: 1210px)").matches) {
  scrollOverflow('.thinking__round-wrapper');
}